import styled from "styled-components";
import colors from "./colors";
import { motion } from "framer-motion";
import IconButton from "@mui/material/IconButton/IconButton";


export const ScoreDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Score = styled.span`
  color: #f7941d;
  font-size: 24px;
  font-weight: 600;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

export const FloatingCat = styled.div`
  position: fixed;
  bottom: 20%;
  left: 0;
  z-index: 20;
`;
export const Icons = styled.img`
  width: 32px;
  height: 32px;

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`;

export const ShineEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shine 3s infinite;

  @keyframes shine {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export const AnimatedScoreDiv = styled(motion.div)<{
  $isHighlighted?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: ${(props) => (props.$isHighlighted ? 30 : 1)};

  ${(props) =>
    props.$isHighlighted &&
    `
    &::after {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
      background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
      animation: pulse 2s infinite;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 0.5;
      }
      50% {
        transform: scale(1.4);
        opacity: 0.8;
      }
      100% {
        transform: scale(1);
        opacity: 0.5;
      }
    }
  `}
`;

export const AnimatedScore = styled(Score)<{ isHighlighted?: boolean }>`
  position: relative;
  z-index: ${(props) => (props.isHighlighted ? 30 : 1)};

  ${(props) =>
    props.isHighlighted &&
    `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 10%,
  
      );
      animation: shine 2s infinite;
    }

    @keyframes shine {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
  `}
`;

export const AnimatedIcon = styled(Icons)<{ isHighlighted?: boolean }>`
  z-index: ${(props) => (props.isHighlighted ? 30 : 1)};
`;

export const scoreVariants = {
  normal: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
  highlighted: {
    scale: [1, 1.3, 1],
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse" as const,
      ease: "easeInOut",
    },
  },
};

export const simpleIconVariants = {
  normal: {
    scale: 1,
  },
  highlighted: {
    scale: [1, 1.2, 1],
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut",
    },
  },
};

export const AnimatedIconButton = styled(IconButton)<{
  isHighlighted?: boolean;
}>`
  position: relative;
  overflow: visible;

  ${(props) =>
    props.isHighlighted &&
    `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        45deg,
        rgba(255,255,255,0) 0%,
        rgba(255,255,255,0.8) 50%,
        rgba(255,255,255,0) 100%
      );
      animation: shine 2s infinite;
    }

    @keyframes shine {
      0% {
        transform: translateX(-100%) rotate(45deg);
      }
      100% {
        transform: translateX(100%) rotate(45deg);
      }
    }
  `}
`;

export const HighlightedIcon = styled(Icons)<{ isHighlighted?: boolean }>`
  filter: ${(props) =>
    props.isHighlighted
      ? "drop-shadow(0 0 8px rgba(255,255,255,0.8))"
      : "none"};
  transition: all 0.3s ease;
`;

export const AnimatedIconContainer = styled(motion.div)<{
  isHighlighted?: boolean;
}>`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: ${(props) => (props.isHighlighted ? 30 : 1)};
`;

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 16px;
  z-index: 20;
`;

export const CarouselContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #f4faff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-block: 10px;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const TopLeftContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 10;

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export const Level = styled.div`
  color: #2965ff;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const Image = styled.img`
  width: 410px;
  max-width: 90%;
  height: auto;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 280px;
    margin: 20px 0;
  }
  @media (max-width: 480px) {
    width: 180px;
    margin: 10px 0;
  }
`;

export const FeaturedTopics = styled.h2`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin: 14px 0;

  @media (max-width: 768px) {
    font-size: 20px;
    margin: 16px 0;
  }
  @media (max-width: 480px) {
    font-size: 17px;
    margin: 12px 0;
  }
`;

export const ChatContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 100px);

  @media (max-width: 768px) {
    padding: 16px;
    height: calc(100vh - 80px);
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  align-items: flex-start;

  @media (max-width: 768px) {
    gap: 12px;
  }
`;

export const ChatAvatar = styled(motion.img)`
  width: 48px;
  height: 48px;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

export const Message = styled.div`
  background: white;
  padding: 16px;
  border-radius: 12px;
  max-width: calc(100% - 64px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 12px;
    font-size: 14px;
  }
`;

export const ChatButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 16px 0;
  padding-left: 64px;

  @media (max-width: 768px) {
    padding-left: 52px;
    gap: 8px;
  }
`;

export const ChatButton = styled.button`
  background: #eef3ff;
  color: #2965ff;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 13px;
  }

  &:hover {
    background: #dce6ff;
  }
`;

export const TopRightIcon = styled(motion.div)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;

  @media (max-width: 768px) {
    top: 16px;
    right: 16px;
  }
`;

export const TopLeftIcons = styled(motion.div)`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10;

  @media (max-width: 768px) {
    top: 16px;
    left: 16px;
    flex-direction: row;
  }
`;

export const ContinueButton = styled(motion.div)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  padding: 12px 24px;
  border: none;
  color: ${colors.lightBlue};
  font-size: 26px;
  font-weight: 600;
  z-index: 100;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    right: 10px;
    padding: 8px 16px;
    font-size: 20px;

    .MuiSvgIcon-root {
      font-size: 28px;
    }
  }

  @media (max-width: 480px) {
    padding: 6px 12px;
    font-size: 16px;

    .MuiSvgIcon-root {
      font-size: 24px;
    }
  }
`;

export const FeaturedTopicsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  position: relative;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 0 16px;
    margin: 20px auto;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 0 10px;
  }
`;

export const iconAnimationVariants = {
  normal: {
    scale: 1,
    filter: "brightness(0.6)",
  },
  highlighted: {
    scale: [1, 1.1, 1],
    filter: "brightness(1)",
    transition: {
      scale: {
        duration: 0.8,
        repeat: Infinity,
        repeatType: "loop",
        ease: "easeInOut",
      },
      filter: {
        duration: 0.3,
      },
    },
  },
};

export const Text = styled.h2`
  color: ${colors.text};
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    margin-bottom: 12px;
  }
`;

export const SpeechText = styled.p`
  color: #085cbe;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  line-height: 1.5;

  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 1.4;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.3;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 1.3;
  }
`;

export const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  x-index: 2000;
  padding: 0;
}
`;

export const ImageAvatar = styled.img`
  width: 320px;
  height: auto;

  @media (max-width: 768px) {
    width: 280px;
  }

  @media (max-width: 480px) {
    width: 180px;
  }
`;

export const SpeechBubble = styled.div`
  position: relative;
  top: 100px;
  right: -250px;
  background: #fff5ee;
  padding: 15px 25px;
  border-radius: 30px;
  font-size: 16px;
  color: #ff9838;
  max-width: 280px;
  text-align: center;

  &:after {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 22%;
    border-width: 12px 8px 0;
    border-style: solid;
    border-color: #fff5ee transparent transparent transparent;
  }

  @media (max-width: 768px) {
    right: -40px;
    max-width: 220px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    top: 70px;
    right: -100px;
    max-width: 110px;
    font-size: 10px;
    padding: 10px 10px;
  }
`;

export const SideCharacter = styled(motion.div)<{ step: number }>`
  position: fixed;
  z-index: 20;

  img {
    width: auto;
    height: 460px;

    @media (max-width: 768px) {
      height: 380px;
    }

    @media (max-width: 480px) {
      height: 250px;
      left: 0;
    }
  }

  ${({ step }) => {
    if (step === 1) {
      return `
        top: 26%;
        left: 5%;
        
        @media (min-width: 1024px) and (max-width: 1366px) {
          top: 30%;
          left: 2%;
          
          img {
            height: 420px;
          }
        }
        
        @media (max-width: 768px) {
          top: 20%;
          left: -3%;
        }
        
        @media (max-width: 480px) {
          top: 25%;
          left: -11%;
        }
      `;
    } else {
      return `
        bottom: 10%;
        left: 8%;
        
        @media (min-width: 1024px) and (max-width: 1366px) {
          bottom: 20%;
          left: 2%;
          
          img {
            height: 420px;
          }
        }
        
        @media (max-width: 768px) {
          bottom: 18%;
          left: 3%;
        }
        
        @media (max-width: 480px) {
          bottom: 30%;
          left: 0;
        }
      `;
    }
  }}
`;

export const SpeechBubbleContainer = styled(motion.div)<{ step: number }>`
  position: fixed;
  background: white;
  padding: 20px 30px;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  z-index: 20;
  text-align: center;

  @media (min-width: 1024px) and (max-width: 1366px) {
    max-width: 280px;
    padding: 16px 24px;
  }

  @media (max-width: 768px) {
    max-width: 180px;
    padding: 12px 16px;
  }

  @media (max-width: 480px) {
    max-width: 160px;
    padding: 10px 14px;
  }

  ${({ step }) => {
    if (step === 1) {
      return `
        top: 40%;
        left: 20%;
        
        @media (min-width: 1024px) and (max-width: 1366px) {
          top: 35%;
          left: 25%;
        }
        
        @media (max-width: 768px) {
          top: 32%;
          left: 15%;
        }
        
        @media (max-width: 480px) {
          top: 36%;
          left: 20%;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 20px;
          left: -11px;
          transform: rotate(180deg);
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 15px solid white;
        }
      `;
    } else {
      return `
        top: 18%;
        left: calc(15% + 300px);
        
        @media (min-width: 1024px) and (max-width: 1366px) {
          top: 55%;
          left: calc(22% + 120px);
        }
        
        @media (max-width: 768px) {
          top: 14%;
          left: calc(6% + 150px); 
        }
        
        @media (max-width: 480px) {
          top: 15%;
          left: calc(13% + 150px); 
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 20px;
          left: -10px;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 15px solid white;
        }
      `;
    }
  }}
`;

export const EmojiSpan = styled.span`
  font-size: 36px;
  display: block;
  margin-bottom: 8px;

  @media (max-width: 1024px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 4px;
  }
`;

export const TextSpan = styled.span`
  font-size: 18px;
  font-weight: 500;

  @media (max-width: 1024px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const TopicButton = styled(motion.button)<{ isHighlighted?: boolean }>`
  background: transparent;
  border: none;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: ${(props) => (props.isHighlighted ? 30 : 1)};
  overflow: hidden;
  min-width: 200px;
  margin-top: 5px;
  font-size: 19px;

  @media (max-width: 1024px) {
    min-height: 80px;
    padding: 12px;
  }

  @media (max-width: 480px) {
    min-height: 60px;
    padding: 8px;
    min-width: 120px;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &:after {
    content: attr(data-category);
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    opacity: 0.8;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 10px;
      bottom: 4px;
    }
  }
`;