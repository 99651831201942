import React, { useContext } from "react";
import LangSwitch from "../components/langSwitch";
import birdIcon from "../assets/bird.png";
import catIcon from "../assets/cat.png";
import {
  WelcomeContainer,
  Header,
  Title,
  Description,
  ContinueButton,
  TopRightContainer,
  LangSwitchContainer,
  BirdImage,
  FoxImage,
  ContentContainer,
  TitleSpan,
} from "../styles/WelcomePageStyles";
import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../hooks/languageContext";
import { availableLanguages } from "../utils/consts";

interface WelcomePageProps {
  onLogout: () => void;
  onContinue: () => void;
}

const WelcomePage: React.FC<WelcomePageProps> = ({ onLogout, onContinue }) => {
  const { t, i18n } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const isRTL = language === "Hebrew";

  const handleLanguageChange = (newLanguage: string) => {
    changeLanguage(newLanguage);
  };

  return (
    <WelcomeContainer dir={i18n.dir()}>
      <TopRightContainer $isRtl={isRTL}>
        <LangSwitchContainer>
          <LangSwitch
            languages={availableLanguages}
            currentLanguage={language}
            onLanguageChange={handleLanguageChange}
          />
        </LangSwitchContainer>
      </TopRightContainer>

      <ContentContainer>
        <Header>
          <Title $isRtl={isRTL}>
            {t("welcome")}{" "}
            <TitleSpan $isRtl={isRTL} style={{ color: "#FFA500" }}>
              {t("tamerino")}
              {"!"}
            </TitleSpan>
          </Title>
        </Header>

        <Description $isRtl={isRTL}>{t("welcomeDescription")}</Description>
        <Description $isRtl={isRTL}>{t("welcomeDescription2")}</Description>

        <ContinueButton onClick={onContinue}>
          <ArrowRight
            size={24}
            style={{ transform: isRTL ? "rotate(180deg)" : "none" }}
          />
        </ContinueButton>
      </ContentContainer>

      <BirdImage src={birdIcon} alt="Bird" />
      <FoxImage src={catIcon} alt="Fox" />
    </WelcomeContainer>
  );
};

export default WelcomePage;
