import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import styled from "styled-components";
import { Pause, Play } from "lucide-react";

const StyledButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 9999px;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export interface IntroAudioPlayerRef {
  stopAudio: () => void;
}

const IntroAudioPlayer = forwardRef<IntroAudioPlayerRef>((props, ref) => {
  const [introAudio, setIntroAudio] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    stopAudio: () => {
      if (audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    },
  }));

  useEffect(() => {
    const storedIntroAudio = sessionStorage.getItem("introAudio");
    if (storedIntroAudio) {
      setIntroAudio(storedIntroAudio);
      initializeAudio(storedIntroAudio);
    }
  }, []);

  const initializeAudio = (audioData: string): void => {
    const audioUrl = decodeBase64ToAudioData(audioData);
    if (!audioRef.current) {
      audioRef.current = new Audio(audioUrl);
      audioRef.current.addEventListener("ended", () => {
        setIsPlaying(false);
      });

      audioRef.current
        .play()
        .then(() => {
          setIsInitialized(true);
        })
        .catch((error) => {
          console.error("Auto-play failed:", error);
          setIsPlaying(false);
          setIsInitialized(true);
        });
    }
  };

  const toggleAudio = (): void => {
    if (!audioRef.current || !isInitialized) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const decodeBase64ToAudioData = (base64Audio: string): string => {
    const binaryString = atob(base64Audio);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const audioBlob = new Blob([bytes.buffer], { type: "audio/mpeg" });
    return URL.createObjectURL(audioBlob);
  };

  return (
    <StyledButton onClick={toggleAudio}>
      {isPlaying ? <Pause size={24} /> : <Play size={24} />}
    </StyledButton>
  );
});

export default IntroAudioPlayer;
