import styled from "styled-components";

interface RTLProps {
  $isrtl?: boolean;
}

export const LoginFormTitle = styled.h2`
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #085cbe;

  @media (max-width: 768px) {
    font-size: 32px;
    margin-bottom: 1.25rem;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    margin-bottom: 1rem;
  }
`;

export const LoginFormField = styled.div`
  margin-bottom: 1.2rem;
  width: 100%;

  @media (max-width: 480px) {
    margin-bottom: 1rem;
  }

  .rtl & {
    text-align: right;
  }
`;

export const LoginFormLabel = styled.label<RTLProps>`
  display: block;
  color: #085cbe;
  font-size: 20px;
  margin-bottom: 0.5rem;
  text-align: ${(props) => (props.$isrtl ? "right" : "left")};
  direction: ${(props) => (props.$isrtl ? "rtl" : "ltr")};
  width: 100%;

  @media (max-width: 768px) {
    width: 380px;
    font-size: 18px;
  }

  @media (max-width: 480px) {
    width: 280px;
    font-size: 16px;
  }
`;

export const LoginFormButton = styled.button`
  width: 100%;
  color: white;
  padding: 0.75rem;
  border-radius: 10px;
  background-color: #085cbe;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.1rem;
  border: none;
  margin-top: 1rem;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.6rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
`;

export const ForgotPassword = styled.div`
  text-align: center;
  margin-top: 1rem;

  a {
    color: #3182ce;
    font-size: 0.9rem;
    text-decoration: none;

    @media (max-width: 768px) {
      font-size: 0.85rem;
    }

    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
`;

export const BirdImage = styled.img`
  position: fixed;
  width: 230px;
  height: auto;
  z-index: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  .rtl & {
    left: 0;
    transform: scaleX(-1);
  }

  :not(.rtl) & {
    right: 0;
  }

  top: 15%;

  @media (max-width: 1024px) {
    width: 180px;
    top: 12%;
  }

  @media (max-width: 768px) {
    width: 120px;
    top: 10%;
    opacity: 0.6;
  }

  @media (max-width: 480px) {
    width: 80px;
    top: 15%;
    opacity: 0.4;
  }
`;

export const FoxImage = styled.img`
  position: fixed;
  bottom: 10%;
  width: 230px;
  height: auto;
  z-index: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  .rtl & {
    right: 0;
    transform: scaleX(-1);
  }

  :not(.rtl) & {
    left: 0;
  }

  @media (max-width: 1024px) {
    width: 180px;
    bottom: 8%;
  }

  @media (max-width: 768px) {
    width: 120px;
    bottom: 5%;
    opacity: 0.6;
  }

  @media (max-width: 480px) {
    width: 80px;
    bottom: 5%;
    opacity: 0.4;
  }
`;

interface RTLProps {
  $isrtl?: boolean;
}

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const MainContainer = styled.div`
  width: 100%;
  max-width: 880px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
  position: relative;

  &.rtl {
    direction: rtl;

    input,
    textarea {
      text-align: right;
    }

    button {
      direction: rtl;
    }
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const LoginFormBox = styled.div<RTLProps>`
  padding: 4rem;
  border-radius: 10px;
  background-color: #eef8ff;
  width: 600px;
  height: auto;
  color: #085cbe;
  align-self: center;
  direction: ${(props) => (props.$isrtl ? "rtl" : "ltr")};

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 1.4rem;
    width: 300px;
  }
`;

export const PasswordInputContainer = styled.div<RTLProps>`
  position: relative;
  direction: ${(props) => (props.$isrtl ? "rtl" : "ltr")};
`;

export const PasswordToggleButton = styled.button<RTLProps>`
  position: absolute;
  top: 50%;
  ${(props) => (props.$isrtl ? "left: 0.5rem;" : "right: 0.5rem;")}
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 0.4rem;
  }
`;

export const RememberMeContainer = styled.div<RTLProps>`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.5rem;
  text-align: ${(props) => (props.$isrtl ? "right" : "left")};
  direction: ${(props) => (props.$isrtl ? "rtl" : "ltr")};

  ${(props) =>
    props.$isrtl
      ? `
    justify-content: flex-start;
    
    input {
      order: 1;
      margin: 0;
    }
    
    label {
      order: 2;
      margin-right: 0.5rem;
      font-size: 0.9rem;
    }
  `
      : `
    justify-content: flex-start;
    
    input {
      order: 1;
      margin: 0;
    }
    
    label {
      order: 2;
      margin-left: 0.5rem;
      font-size: 0.9rem;
    }
  `}

  @media (max-width: 768px) {
    label {
      font-size: 0.85rem;
    }
  }

  @media (max-width: 480px) {
    label {
      font-size: 0.8rem;
    }
  }
`;

export const PasswordText = styled.p<RTLProps>`
  font-size: 0.8rem;
  color: #4a5568;
  margin-top: 0.5rem;
  text-align: ${(props) => (props.$isrtl ? "right" : "left")};
  direction: ${(props) => (props.$isrtl ? "rtl" : "ltr")};

  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
`;

export const ErrorContainer = styled.div<RTLProps>`
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  border-radius: 8px;
  padding: 0.75rem;
  margin: 1rem 0;
  position: relative;
  text-align: ${(props) => (props.$isrtl ? "right" : "left")};
  direction: ${(props) => (props.$isrtl ? "rtl" : "ltr")};

  @media (max-width: 768px) {
    padding: 0.5rem;
    margin: 0.75rem 0;
  }

  @media (max-width: 480px) {
    padding: 0.4rem;
    margin: 0.5rem 0;
  }
`;

export const ErrorText = styled.p<RTLProps>`
  color: #dc2626;
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 0;
  text-align: ${(props) => (props.$isrtl ? "right" : "left")};
  direction: ${(props) => (props.$isrtl ? "rtl" : "ltr")};

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

export const FormFieldError = styled.div<RTLProps>`
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #dc2626;
  text-align: ${(props) => (props.$isrtl ? "right" : "left")};
  direction: ${(props) => (props.$isrtl ? "rtl" : "ltr")};

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    gap: 0.4rem;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 480px) {
    gap: 0.3rem;

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const LoginFormInput = styled.input<RTLProps & { hasError?: boolean }>`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${(props) => (props.hasError ? "#DC2626" : "#D1D5DB")};
  border-radius: 8px;
  font-size: 1rem;
  text-align: ${(props) => (props.$isrtl ? "right" : "left")};
  direction: ${(props) => (props.$isrtl ? "rtl" : "ltr")};
  background-color: ${(props) => (props.hasError ? "#FEF2F2" : "white")};
  transition:
    border-color 0.2s,
    background-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${(props) => (props.hasError ? "#DC2626" : "#085CBE")};
    box-shadow: 0 0 0 2px
      ${(props) =>
        props.hasError ? "rgba(220, 38, 38, 0.1)" : "rgba(8, 92, 190, 0.1)"};
  }

  @media (max-width: 768px) {
    padding: 0.6rem;
    font-size: 0.95rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
`;
