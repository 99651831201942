import React from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  Title,
  Subtitle,
  AvatarOption,
  AvatarSelectionWrapper,
} from "../styles/botSetup.styles";
import birdAvatar from "../assets/birdAvatar.png";
import catAvatar from "../assets/catAvatar.png";
import robotAvatar from "../assets/robotAvatar.png";

const AvatarSelection: React.FC<{
  setSelectedAvatar: (avatar: string) => void;
  selectedAvatar: string;
}> = ({ setSelectedAvatar, selectedAvatar }) => {
  const { t } = useTranslation();
  const handleSelect = (avatar: string) => {
   sessionStorage.setItem("avatarType", avatar);
    setSelectedAvatar(avatar);
  };

  return (
    <Column>
      <Subtitle>{t("select_character")}</Subtitle>
      <AvatarSelectionWrapper>
        <AvatarOption
          onClick={() => handleSelect("1")}
          style={{
            backgroundImage: `url(${catAvatar})`,
            border: selectedAvatar === "1" ? "4px solid #085CBE" : "",
          }}
        />
        <AvatarOption
          onClick={() => handleSelect("2")}
          style={{
            backgroundImage: `url(${birdAvatar})`,
            border: selectedAvatar === "2" ? "4px solid #085CBE" : "",
          }}
        />
        <AvatarOption
          onClick={() => handleSelect("3")}
          style={{
            backgroundImage: `url(${robotAvatar})`,
            border: selectedAvatar === "3" ? "4px solid #085CBE" : "",
          }}
        />
      </AvatarSelectionWrapper>
    </Column>
  );
};

export default AvatarSelection;
