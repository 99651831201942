import React, { useState, useContext, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { registerUser, loginUser, resetPassword } from "../apis/loginApi";
import { ToastContainer, toast } from "react-toastify";
import { LanguageContext } from "../hooks/languageContext";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import {
  MainContainer,
  LoginFormBox,
  LoginFormInput,
  PasswordInputContainer,
  PasswordToggleButton,
  RememberMeContainer,
  ForgotPassword,
  LoginFormButton,
  LoginFormField,
  LoginFormLabel,
  LoginFormTitle,
} from "../styles/LoginFormStyles";
import {
  BirdImage,
  DialogButton,
  DialogContent,
  DialogOverlay,
  DialogText,
  DialogTitle,
  FoxImage,
  InfoBox,
  LangSwitchContainer,
  TopRightContainer,
} from "../styles/WelcomePageStyles";
import birdIcon from "../assets/bird.png";
import catIcon from "../assets/cat.png";
import LangSwitch from "../components/langSwitch";
import { availableLanguages } from "../utils/consts";

interface LoginFormProps {
  onLogin: (idToken: string, uid: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { t, i18n } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const isRTL = language === "Hebrew";
  const [showVerificationDialog, setShowVerificationDialog] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassword: "",
  });

  useEffect(() => {
    i18n.changeLanguage(language);
    const savedEmail = localStorage.getItem("savedEmail");
    const savedPassword = localStorage.getItem("savedPassword");
    if (savedEmail && savedPassword) {
      setFormData((prevData) => ({
        ...prevData,
        email: savedEmail,
        password: savedPassword,
      }));
      setRememberMe(true);
    }
  }, [language, i18n]);

  const handleLanguageChange = (newLanguage: string) => {
    changeLanguage(newLanguage);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const isEmailValid = (email: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const isPasswordValid = (password: string) => {
    return password.length >= 6;
  };

  const getValidationErrors = () => {
    const errors = [];

    if (showSignUp) {
      if (!formData.firstName.trim()) {
        errors.push(t("firstNameRequired"));
      }
      if (!formData.lastName.trim()) {
        errors.push(t("lastNameRequired"));
      }
      if (!formData.email.trim()) {
        errors.push(t("emailRequired"));
      } else if (!isEmailValid(formData.email)) {
        errors.push(t("invalidEmail"));
      }
      if (!formData.password) {
        errors.push(t("passwordRequired"));
      } else if (!isPasswordValid(formData.password)) {
        errors.push(t("passwordMinLength"));
      }
      if (formData.password !== formData.repeatPassword) {
        errors.push(t("passwordsMustMatch"));
      }
    } else {
      if (!formData.email.trim()) {
        errors.push(t("emailRequired"));
      } else if (!isEmailValid(formData.email)) {
        errors.push(t("invalidEmail"));
      }
      if (!formData.password) {
        errors.push(t("passwordRequired"));
      }
    }

    return errors;
  };

  const isFormValid = () => {
    if (showSignUp) {
      return (
        formData.firstName.trim() &&
        formData.lastName.trim() &&
        isEmailValid(formData.email) &&
        isPasswordValid(formData.password) &&
        formData.password === formData.repeatPassword
      );
    }
    return formData.email && formData.password;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = getValidationErrors();
    if (validationErrors.length > 0) {
      validationErrors.forEach((error) => {
        toast.error(error, {
          position: "bottom-center",
          style: { backgroundColor: "#EF4444", color: "white" },
          autoClose: 3000,
        });
      });
      return;
    }

    setLoading(true);
    try {
      if (showSignUp) {
        await registerUser(
          formData.email,
          formData.password,
          `${formData.firstName} ${formData.lastName}`
        );
        setShowSignUp(false);
        setRegisteredEmail(formData.email);
        setShowVerificationDialog(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          repeatPassword: "",
        });
      } else {
        const response = await loginUser(formData.email, formData.password);
        if (rememberMe) {
          localStorage.setItem("savedEmail", formData.email);
          localStorage.setItem("savedPassword", formData.password);
        } else {
          localStorage.removeItem("savedEmail");
          localStorage.removeItem("savedPassword");
        }
        onLogin(response.token, response.uid);
      }
    } catch (error: any) {
      toast.error(error?.message || t("invalidCredentials"), {
        position: "bottom-center",
        style: {
          backgroundColor: "#EF4444",
          color: "white",
          borderRadius: "10px",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (!formData.email) {
      toast.error(t("emailRequired"), {
        position: "bottom-center",
        style: { backgroundColor: "#EF4444", color: "white" },
      });
      return;
    }

    if (!isEmailValid(formData.email)) {
      toast.error(t("invalidEmail"), {
        position: "bottom-center",
        style: { backgroundColor: "#EF4444", color: "white" },
      });
      return;
    }

    setLoading(true);
    try {
      await resetPassword(formData.email);
      toast.success(t("resetLinkSent"));
    } catch (error) {
      toast.error(t("resetLinkFailed"), {
        position: "bottom-center",
        style: { backgroundColor: "#EF4444", color: "white" },
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <MainContainer className={isRTL ? "rtl" : ""}>
      <TopRightContainer $isRtl={isRTL}>
        <LangSwitchContainer>
          <LangSwitch
            languages={availableLanguages}
            currentLanguage={language}
            onLanguageChange={handleLanguageChange}
          />
        </LangSwitchContainer>
      </TopRightContainer>

      <form onSubmit={handleSubmit}>
        <LoginFormBox $isrtl={isRTL}>
          <LoginFormTitle>
            {showSignUp ? t("signUp") : t("signIn")}
          </LoginFormTitle>

          {showSignUp && (
            <>
              <LoginFormField>
                <LoginFormLabel $isrtl={isRTL}>{t("firstName")}</LoginFormLabel>
                <LoginFormInput
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  autoComplete="given-name"
                  $isrtl={isRTL}
                />
              </LoginFormField>
              <LoginFormField>
                <LoginFormLabel $isrtl={isRTL}>{t("lastName")}</LoginFormLabel>
                <LoginFormInput
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  autoComplete="family-name"
                  $isrtl={isRTL}
                />
              </LoginFormField>
            </>
          )}

          <LoginFormField>
            <LoginFormLabel $isrtl={isRTL}>{t("email")}</LoginFormLabel>
            <LoginFormInput
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              autoComplete="username"
              $isrtl={isRTL}
            />
          </LoginFormField>

          <LoginFormField>
            <LoginFormLabel $isrtl={isRTL}>{t("password")}</LoginFormLabel>
            <PasswordInputContainer $isrtl={isRTL}>
              <LoginFormInput
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                autoComplete={showSignUp ? "new-password" : "current-password"}
                $isrtl={isRTL}
              />
              <PasswordToggleButton
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                $isrtl={isRTL}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </PasswordToggleButton>
            </PasswordInputContainer>
          </LoginFormField>

          {showSignUp && (
            <LoginFormField>
              <LoginFormLabel $isrtl={isRTL}>
                {t("repeatPassword")}
              </LoginFormLabel>
              <PasswordInputContainer $isrtl={isRTL}>
                <LoginFormInput
                  type={showPassword ? "text" : "password"}
                  name="repeatPassword"
                  value={formData.repeatPassword}
                  onChange={handleInputChange}
                  autoComplete="new-password"
                  $isrtl={isRTL}
                />
                <PasswordToggleButton
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  $isrtl={isRTL}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </PasswordToggleButton>
              </PasswordInputContainer>
            </LoginFormField>
          )}

          {!showSignUp && (
            <RememberMeContainer $isrtl={isRTL}>
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label htmlFor="rememberMe">{t("rememberMe")}</label>
            </RememberMeContainer>
          )}

          <LoginFormButton type="submit" disabled={loading}>
            {loading ? (
              <ClipLoader color="#ffffff" size={20} />
            ) : showSignUp ? (
              t("signUp")
            ) : (
              t("signIn")
            )}
          </LoginFormButton>

          {!showSignUp && (
            <ForgotPassword>
              <a href="#" onClick={handleResetPassword}>
                {t("forgotPassword")}
              </a>
            </ForgotPassword>
          )}

          <ForgotPassword>
            <a
              href="#"
              onClick={() => {
                setShowSignUp(!showSignUp);
                setFormData({
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  repeatPassword: "",
                });
                setShowPassword(false);
              }}
            >
              {showSignUp ? t("alreadyRegistered") : t("needAccount")}
            </a>
          </ForgotPassword>
        </LoginFormBox>
      </form>

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={isRTL}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
        style={{ width: "100%", maxWidth: "400px" }}
      />

      <BirdImage src={birdIcon} alt="Bird" />
      <FoxImage src={catIcon} alt="Fox" />
      {showVerificationDialog && (
        <DialogOverlay>
          <DialogContent>
            <FaEnvelope
              size={40}
              color="#3b82f6"
              style={{ margin: "0 auto 1rem" }}
            />
            <DialogTitle>{t("emailVerificationRequired")}</DialogTitle>
            <DialogText>
              {t("verificationEmailSent", { email: registeredEmail })}
            </DialogText>
            <InfoBox>
              <FaEnvelope size={20} color="#64748b" />
              <DialogText style={{ margin: 0 }}>
                {t("checkSpamFolder")}
              </DialogText>
            </InfoBox>
            <DialogButton
              onClick={() => {
                setShowVerificationDialog(false);
                setShowSignUp(false);
              }}
            >
              {t("understood")}
            </DialogButton>
          </DialogContent>
        </DialogOverlay>
      )}
    </MainContainer>
  );
};

export default LoginForm;