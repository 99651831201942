const colors = {
  yellow: "#fff11d",
  orange: "#FF9668",
  pink: "#DB456F",
  purple: "#A74FAF",
  text: "#085CBE",
  primary: "#FFA500",
  textLight: "#A0A0A0",
  border: "#E0E0E0",
  lightBlue: "#EEF8FF",
};

export default colors;
