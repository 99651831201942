import React, { useContext, useState } from "react";
import {
  Button,
  ButtonList,
  CloseButton,
  ContentArea,
  DialogContent,
  DialogOverlay,
  SubTitle,
  ContentWrapper,
  DialogTitle,
  Row,
} from "../styles/SettigsDialogStyle";
import LangSwitch from "./langSwitch";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../hooks/languageContext";
interface Language {
  alpha2: string;
  English: string;
}

type SettingSection =
  | "Version"
  | "Language"
  | "Time Limit"
  | "Help"
  | "Sign out";

interface SettingsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onLogout: () => void;
  onLanguageChange: (language: Language) => void;
  currentLanguage: Language;
  availableLanguages: Language[];
}

const SettingsDialog: React.FC<SettingsDialogProps> = ({
  isOpen,
  onClose,
  onLogout,
  availableLanguages,
}) => {
  const [selectedSection, setSelectedSection] =
    useState<SettingSection>("Version");
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const isRTL = language === "Hebrew";

  const handleLanguageChange = (newLanguage: string) => {
    changeLanguage(newLanguage);
  };

  if (!isOpen) return null;

  const sections: SettingSection[] = [
    "Version",
    "Language",
    "Time Limit",
    "Sign out",
  ];

  const renderContent = () => {
    switch (selectedSection) {
      case "Version":
        return (
          <>
            <SubTitle $isRtl={isRTL}>
              {t("settingsDialog.version.current")}
            </SubTitle>
            <SubTitle $isRtl={isRTL}>
              {t("settingsDialog.version.premiumDescription")}
            </SubTitle>
          </>
        );
      case "Language":
        return (
          <LangSwitch
            languages={availableLanguages}
            currentLanguage={language}
            onLanguageChange={handleLanguageChange}
          />
        );
      case "Time Limit":
        return (
          <SubTitle $isRtl={isRTL}>
            {t("settingsDialog.timeLimit.comingSoon")}
          </SubTitle>
        );
      default:
        return null;
    }
  };

  return (
    <DialogOverlay>
      <DialogContent $isRtl={isRTL}>
        <DialogTitle $isRtl={isRTL}>{t("settingsDialog.title")}</DialogTitle>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <Row>
          <ButtonList $isRtl={isRTL}>
            {sections.map((section) => (
              <Button
                key={section}
                $isselected={selectedSection === section}
                $isLogout={section === "Sign out"}
                $isRtl={isRTL}
                onClick={() => {
                  if (section === "Sign out") {
                    onLogout();
                  } else {
                    setSelectedSection(section);
                  }
                }}
              >
                {t(
                  `settingsDialog.sections.${section.toLowerCase().replace(" ", "_")}`
                )}
              </Button>
            ))}
          </ButtonList>
          <ContentArea $isRtl={isRTL}>
            <ContentWrapper $isRtl={isRTL}>{renderContent()}</ContentWrapper>
          </ContentArea>
        </Row>
      </DialogContent>
    </DialogOverlay>
  );
};

export default SettingsDialog;