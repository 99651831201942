import { baseBackendUrl } from "../utils/baseUrls";

export interface Language {
  alpha2: string;
  English: string;
}
export interface BotSettings {
  languages: Language[];
  topics: string[];
  answerLength: string[];
}

export const getBotSettings = async (idToken: string): Promise<BotSettings> => {
  const response = await fetch(`${baseBackendUrl}/chatbot/get-bot-settings`, {
    method: "GET",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      Authorization: idToken,
      "Content-Type": "application/json",
      "X-Source": window.location.origin,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to fetch bot settings");
  }

  return response.json();
};

export const createTutor = async (
  age: string,
  language: string,
  topic: string,
  avatarName: string,
  idToken: string,
  password: string
): Promise<any> => {
  const requestBody = {
    targetAge: age,
    language: language,
    topic: topic,
    avatarName: avatarName,
    code: password,
  };

  const response = await fetch(`${baseBackendUrl}/chatbot/create`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      "X-Source": window.location.origin,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to create AI Tutor");
  }

  return response.json();
};

export const updateTutor = async (
  chatbotId: string,
  idToken: string,
  language?: string,
  topics?: string[],
  answerType?: string,
  colorTheme?: string,
  avatarType?: string,
  azureVoiceType?: string,
  selectedAge?: string | number
): Promise<any> => {
  const requestBody = {
    chatbotId: chatbotId,
    topics: topics,
    language: language,
    answerType: answerType,
    colorTheme: colorTheme,
    avatarType: avatarType,
    azureVoiceType: azureVoiceType,
    targetAge: selectedAge,
  };

  const response = await fetch(`${baseBackendUrl}/chatbot/update`, {
    method: "PUT",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
      Authorization: `${idToken}`,
      "X-Source": window.location.origin,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to update AI Tutor");
  }

  return response.json();
};

export const getAllUsersBots = async (authToken: string) => {
  const response = await fetch(`${baseBackendUrl}/chatbot/get-all`, {
    method: "GET",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      Authorization: authToken,
      "Content-Type": "application/json",
      "X-Source": window.location.origin,
    },
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      `HTTP error! status: ${response.status}, response: ${errorText}`
    );
  }

  return response.json();
};

export const deleteUserBot = async (authToken: string, botId: string) => {
  const requestBody = {
    chatbotId: botId,
  };
  const response = await fetch(`${baseBackendUrl}/chatbot/delete`, {
    method: "DELETE",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
      Authorization: authToken,
      "X-Source": window.location.origin,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to delete AI Tutor");
  }
  return response.json();
};

export const getBotById = async (
  authToken: string,
  botId: string
): Promise<any> => {
  const requestBody = {
    chatbotId: botId,
  };

  const response = await fetch(`${baseBackendUrl}/chatbot/get`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      Authorization: authToken,
      "Content-Type": "application/json",
      "X-Source": window.location.origin,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to fetch AI Tutor");
  }

  return response.json();
};

export const getSampleVoice = async (
  voiceType: string,
  language: string
): Promise<Blob> => {
  const response = await fetch(`${baseBackendUrl}/chatbot/get-sample-voice`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
      "X-Source": window.location.origin,
    },
    body: JSON.stringify({
      azureVoiceType: voiceType,
      language,
    }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to fetch voice sample: ${errorText}`);
  }

  try {
    const jsonResponse = await response.json();
    if (jsonResponse.audio) {
      const byteCharacters = atob(jsonResponse.audio);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "audio/mp3" });
    }
  } catch {
    return response.blob();
  }

  throw new Error("Invalid response format from server");
};


interface ChatbotResponse {
  languages: Array<{ alpha2: string; English: string }>;
  topics: string[];
  answerLength: string[];
  selectedLanguage?: string;
  selectedTopics?: string[];
  selectedAnswerLength?: string;
}

export const getChatbotSettings = async (token: string, chatbotId: string) => {
  try {
    const response = await fetch(`${baseBackendUrl}/chatbot/get`, {
      headers: {
        Authorization: token,
        "x-api-key": process.env.REACT_APP_BACKEND_KEY || "",
        "x-source": window.location.origin,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ chatbotId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to fetch chatbot settings");
    }
  } catch (error) {
    console.error("Failed to fetch chatbot settings:", error);
    throw new Error("Failed to fetch chatbot settings");
  }
};