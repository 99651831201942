import React, { useEffect, useState } from "react";
import BotSetup from "./botsSetup";
import IntroPage from "./introPage";
import ChatPage from "./ChatPage";
import {
  ChatSessionResponse,
  LanguageMap,
  SessionDataWithView,
  UserConfig,
} from "../utils/interfaces";
import { changeLanguage } from "i18next";
import { availableLanguages } from "../utils/consts";

const StartBotPage: React.FC = () => {
  const [currentView, setCurrentView] = useState<"setup" | "intro" | "chat">(
    "setup"
  );
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [userConfig, setUserConfig] = useState<UserConfig>({
    userId: new URLSearchParams(window.location.search).get("userId") || "",
    code: "",
    selectedAvatar: "",
    selectedVoice: "",
    selectedTheme: "",
  });

  const [sessionData, setSessionData] = useState<ChatSessionResponse | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const urlLang = urlParams.get("lang") || "en";

  useEffect(() => {
    if (urlLang) {
      const languageMap: LanguageMap = {
        he: { alpha2: "he", English: "Hebrew" },
        en: { alpha2: "en", English: "English" },
        de: { alpha2: "de", English: "German" },
      };

      const languageMapping = languageMap[urlLang];
      if (
        languageMapping &&
        availableLanguages
          .map((lang) => lang.English)
          .includes(languageMapping.English)
      ) {
        changeLanguage(languageMapping.English);
      }
    }
  }, [changeLanguage]);

  const completeSetup = async (config: Partial<UserConfig>): Promise<void> => {
    try {
      setUserConfig((prev) => ({ ...prev, ...config }));

      if (!sessionData) {
        const storedUserDoc = sessionStorage.getItem("userDoc");
        if (storedUserDoc) {
          const reconstructedData: ChatSessionResponse = {
            chatbotId: sessionStorage.getItem("chatbotId") || "",
            sessionId: sessionStorage.getItem("sessionId") || "",
            avatarName: sessionStorage.getItem("avatarName") || "",
            avatarType: sessionStorage.getItem("avatarType") || "",
            azureVoiceType: sessionStorage.getItem("azureVoiceType") || "",
            level: sessionStorage.getItem("level") || "",
            points: sessionStorage.getItem("points") || "",
            colorTheme: sessionStorage.getItem("colorTheme") || "",
            subtopics: JSON.parse(sessionStorage.getItem("subtopics") || "[]"),
            content: sessionStorage.getItem("content") || "",
            followUps: JSON.parse(sessionStorage.getItem("followUps") || "[]"),
            audio: sessionStorage.getItem("audio") || "",
            userDoc: JSON.parse(storedUserDoc),
          };
          setSessionData(reconstructedData);
        }
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : "An error occurred");
      throw error;
    }finally{
      setCurrentView("chat");
    }
  };

  const completeIntro = async (): Promise<void> => {
    try {
      if (!sessionData) return;

      const updatedUserDoc = {
        ...sessionData.userDoc,
      };

      const updatedSessionData = {
        ...sessionData,
        userDoc: updatedUserDoc,
      };

      sessionStorage.setItem("userDoc", JSON.stringify(updatedUserDoc));

      setSessionData(updatedSessionData);
      setCurrentView("chat");
    } catch (error) {
      console.error("Error completing intro:", error);
      throw error;
    }
  };

  const handleSessionData = (data: SessionDataWithView) => {
    Object.entries(data).forEach(([key, value]) => {
      sessionStorage.setItem(
        key,
        typeof value === "object" ? JSON.stringify(value) : value
      );
    });
    setSessionData(data);

    if (data.userDoc.setupComplete) {
      setCurrentView("setup");
      // } else if (!data.userDoc.tutorialComplete) {
      //   setCurrentView("intro");
    } else {
      setCurrentView("chat");
    }
  };

  const handleNavigateToSetup = () => {
    setStepIndex(2);
    setCurrentView("setup");
  };

  const renderCurrentView = () => {
    switch (currentView) {
      case "setup":
        return (
          <BotSetup
            onComplete={completeSetup}
            onSessionData={handleSessionData}
            initialStep={stepIndex}
          />
        );
      case "chat":
        if (!sessionData) return null;
        return (
          <div className="w-full h-full">
            <ChatPage
              initialData={sessionData}
              onNavigateToSetup={handleNavigateToSetup}
            />
          </div>
        );
      default:
        return null;
    }
  };

  if (error) {
    return (
      <div className="p-4 text-red-600 bg-red-100 rounded">
        <p>Error: {error}</p>
      </div>
    );
  }

  return <div className="w-full h-full">{renderCurrentView()}</div>;
};

export default StartBotPage;
