import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LanguageProvider } from "./hooks/languageContext";
import { useState, createContext, useContext } from "react";
import WelcomePage from "./pages/welcomePage";
import LoginForm from "./components/LoginForm";
import ResetPasswordPage from "./pages/resetPasswordPage";
import SettingsPage from "./pages/SettingsPage";
import IntroPage from "./pages/introPage";
import { ThemeProvider, createTheme } from "@mui/material";
import StartBotPage from "./pages/startingPage";
import { useNavigate } from "react-router-dom";
import ConfirmEmail from "./components/ConfirmEmail";

interface AuthContextType {
  isAuthenticated: boolean;
  idToken: string;
  uid: string;
  handleLogin: (token: string, uid: string) => void;
  handleLogout: () => void;
}

interface SetupContextType {
  hasCompletedSetup: boolean;
  hasCompletedIntro: boolean;
  setHasCompletedSetup: (value: boolean) => void;
  setHasCompletedIntro: (value: boolean) => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);
export const SetupContext = createContext<SetupContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const useSetup = () => {
  const context = useContext(SetupContext);
  if (!context) {
    throw new Error("useSetup must be used within a SetupProvider");
  }
  return context;
};

const AppContent: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [idToken, setIdToken] = useState("");
  const [uid, setUid] = useState("");
  const [hasSeenWelcome, setHasSeenWelcome] = useState(false);
  const [hasCompletedSetup, setHasCompletedSetup] = useState(false);
  const [hasCompletedIntro, setHasCompletedIntro] = useState(false);
  const navigate = useNavigate();

  const theme = createTheme({});

  const handleLogin = (token: string, uid: string) => {
    setIsAuthenticated(true);
    setIdToken(token);
    setUid(uid);
    navigate("/settings");
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setIdToken("");
    setUid("");
    setHasSeenWelcome(false);
    setHasCompletedSetup(false);
    setHasCompletedIntro(false);
    navigate("/");
  };

  const handleContinue = () => {
    setHasSeenWelcome(true);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        idToken,
        uid,
        handleLogin,
        handleLogout,
      }}
    >
      <SetupContext.Provider
        value={{
          hasCompletedSetup,
          hasCompletedIntro,
          setHasCompletedSetup,
          setHasCompletedIntro,
        }}
      >
        <ThemeProvider theme={theme}>
          <LanguageProvider>
            <Routes>
              <Route
                path="/"
                element={
                  !hasSeenWelcome ? (
                    <WelcomePage
                      onContinue={handleContinue}
                      onLogout={handleLogout}
                    />
                  ) : (
                    <LoginForm onLogin={handleLogin} />
                  )
                }
              />

              <Route
                path="/settings"
                element={
                  <SettingsPage
                    onLogout={handleLogout}
                    idToken={idToken}
                    uid={uid}
                  />
                }
              />

              <Route path="/chatbot-page" element={<StartBotPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/confirm-email" element={<ConfirmEmail />} />
              <Route
                path="/login"
                element={<LoginForm onLogin={handleLogin} />}
              />

              <Route
                path="/intro"
                element={
                  <IntroPage
                    onComplete={function (): Promise<void> {
                      throw new Error("Function not implemented.");
                    }}
                    language={""}
                  />
                }
              />
            </Routes>
          </LanguageProvider>
        </ThemeProvider>
      </SetupContext.Provider>
    </AuthContext.Provider>
  );
};

// Main App component
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;