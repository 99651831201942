import { FC, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import {
  CarouselContainer,
  ContinueButton,
  FeaturedTopicsContainer,
  TopicButton,
  Level,
  Text,
  Image,
  Icons,
  Overlay,
  SpeechBubbleContainer,
  SpeechText,
  SideCharacter,
  ShineEffect,
  AnimatedScoreDiv,
  AnimatedIcon,
  AnimatedScore,
  scoreVariants,
  iconAnimationVariants,
  TopLeftIcons,
  TopRightIcon,
} from "../styles/IntroCarousel.styles";
import centeredCat from "../assets/centeredCat.png";
import achievement from "../assets/Achievement.png";
import clock from "../assets/clock.svg";
import help from "../assets/help.svg";
import editProfile from "../assets/editProfile.svg";
import sideCat from "../assets/cat.png";
import cat2 from "../assets/cat2.png";
import cat3 from "../assets/cat3.png";
import { useNavigate } from "react-router-dom";
import { LanguageMap } from "../utils/interfaces";
import { changeLanguage } from "i18next";
import { availableLanguages } from "../utils/consts";

interface IntroStep {
  id: number;
  messageKey: string;
  character: "orange-cat" | "brown-cat";
  topics?: string[];
}

interface IntroPageProps {
  onComplete: () => Promise<void>;
  language: string;
}

const steps: IntroStep[] = [
  {
    id: 1,
    messageKey: "steps.1.message",
    character: "orange-cat",
    topics: [
      "intro_topics.poles",
      "intro_topics.plants",
      "intro_topics.hibernate",
      "intro_topics.art_styles",
      "intro_topics.abstract_art",
      "intro_topics.time_zones",
    ],
  },
  {
    id: 1,
    messageKey: "steps.2.message",
    character: "orange-cat",
    topics: [
      "intro_topics.poles",
      "intro_topics.plants",
      "intro_topics.hibernate",
      "intro_topics.art_styles",
      "intro_topics.abstract_art",
      "intro_topics.time_zones",
    ],
  },
  {
    id: 1,
    messageKey: "steps.3.message",
    character: "orange-cat",
    topics: [
      "intro_topics.poles",
      "intro_topics.plants",
      "intro_topics.hibernate",
      "intro_topics.art_styles",
      "intro_topics.abstract_art",
      "intro_topics.time_zones",
    ],
  },
  {
    id: 1,
    messageKey: "steps.4.message",
    character: "orange-cat",
    topics: [
      "intro_topics.poles",
      "intro_topics.plants",
      "intro_topics.hibernate",
      "intro_topics.art_styles",
      "intro_topics.abstract_art",
      "intro_topics.time_zones",
    ],
  },
  {
    id: 1,
    messageKey: "steps.5.message",
    character: "orange-cat",
    topics: [
      "intro_topics.poles",
      "intro_topics.plants",
      "intro_topics.hibernate",
      "intro_topics.art_styles",
      "intro_topics.abstract_art",
      "intro_topics.time_zones",
    ],
  },
  {
    id: 1,
    messageKey: "steps.6.message",
    character: "orange-cat",
    topics: [
      "intro_topics.poles",
      "intro_topics.plants",
      "intro_topics.hibernate",
      "intro_topics.art_styles",
      "intro_topics.abstract_art",
      "intro_topics.time_zones",
    ],
  },
  {
    id: 1,
    messageKey: "steps.7.message",
    character: "orange-cat",
    topics: [
      "intro_topics.poles",
      "intro_topics.plants",
      "intro_topics.hibernate",
      "intro_topics.art_styles",
      "intro_topics.abstract_art",
      "intro_topics.time_zones",
    ],
  },
];

const IntroPage: FC<IntroPageProps> = ({ onComplete, language }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [direction, setDirection] = useState(0);
  const { t } = useTranslation();
  const isRTL = language === "Hebrew";
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const urlLang = urlParams.get("lang");
    if (urlLang) {
      const languageMap: LanguageMap = {
        he: { alpha2: "he", English: "Hebrew" },
        en: { alpha2: "en", English: "English" },
        de: { alpha2: "de", English: "German" },
      };

      const languageMapping = languageMap[urlLang];
      if (
        languageMapping &&
        availableLanguages
          .map((lang) => lang.English)
          .includes(languageMapping.English)
      ) {
        changeLanguage(languageMapping.English);
      }
    }
  }, [changeLanguage]);

  const getTopicStyle = (topicKey: string) => {
    const topic = t(topicKey);
    if (topic.includes(t("categories.geography"))) {
      return {
        backgroundColor: "#eef3ff",
        color: "#2965ff",
        category: t("categories.geography"),
      };
    } else if (topic.includes(t("categories.biology"))) {
      return {
        backgroundColor: "#f1ffee",
        color: "#4caf50",
        category: t("categories.biology"),
      };
    } else if (topic.includes(t("categories.art"))) {
      return {
        backgroundColor: "#fff5ee",
        color: "#f7941d",
        category: t("categories.art"),
      };
    }
    return {
      backgroundColor: "#eef3ff",
      color: "#2965ff",
      category: t("categories.geography"),
    };
  };

  const goToNextStep = () => {
    if (currentStep < steps.length - 1) {
      setDirection(1);
      setCurrentStep((prev) => prev + 1);
    } else if (currentStep === steps.length - 1) {
      onComplete();
    }
  };

  const currentStepData = steps[currentStep];

  if (!currentStepData) {
    return null;
  }

  const getCharacterImage = (step: number) => {
    const images = [sideCat, cat2, cat3, cat2, cat3, cat2, cat3, cat2];
    return images[step] || sideCat;
  };

  const variants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <CarouselContainer dir={isRTL ? "rtl" : "ltr"}>
      <Overlay />
      <ContinueButton
        onClick={goToNextStep}
        animate={{
          x: [-4, 4, -4],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        {t("continue")}
        <ArrowForwardIcon
          sx={{
            fontSize: 48,
            transform: isRTL ? "rotate(180deg)" : "none",
          }}
        />
      </ContinueButton>

      <AnimatePresence mode="wait">
        <SpeechBubbleContainer
          key={`speech-${currentStep}`}
          step={currentStep + 1}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={variants}
        >
          <SpeechText>{t(currentStepData.messageKey)}</SpeechText>
        </SpeechBubbleContainer>

        <SideCharacter
          key={`character-${currentStep}`}
          step={currentStep + 1}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={variants}
        >
          <img
            src={getCharacterImage(currentStep)}
            alt={t("alt.guide_character")}
          />
        </SideCharacter>
      </AnimatePresence>

      <TopRightIcon
        animate={currentStep === 3 ? "highlighted" : "normal"}
        variants={iconAnimationVariants}
      >
        <Icons src={editProfile} alt={t("alt.edit_profile")} />
      </TopRightIcon>

      <TopLeftIcons>
        <motion.div
          animate={currentStep === 5 ? "highlighted" : "normal"}
          variants={iconAnimationVariants}
        >
          <Icons src={help} alt={t("alt.help")} />
        </motion.div>

        <motion.div
          animate={currentStep === 4 ? "highlighted" : "normal"}
          variants={iconAnimationVariants}
        >
          <Icons src={clock} alt={t("alt.history")} />
        </motion.div>
      </TopLeftIcons>

      <Level>{t("level")} 1</Level>
      <AnimatedScoreDiv
        $isHighlighted={currentStep === 2}
        animate={currentStep === 2 ? "highlighted" : "normal"}
        variants={scoreVariants}
      >
        <AnimatedIcon
          src={achievement}
          isHighlighted={currentStep === 2}
          style={{
            filter:
              currentStep === 2
                ? "drop-shadow(0 0 8px rgba(255,255,255,0.8))"
                : "none",
          }}
        />
        <AnimatedScore isHighlighted={currentStep === 2}>367</AnimatedScore>
      </AnimatedScoreDiv>

      <Image src={centeredCat} alt={t("alt.centered_cat")} />
      {currentStepData.topics && (
        <>
          <Text>{t("featured_topics")}</Text>
          <FeaturedTopicsContainer>
            {currentStepData.topics.map((topicKey, index) => {
              const isArtStyles = topicKey === "intro_topics.art_styles";
              const shouldHighlight = currentStep === 1 && isArtStyles;
              const topicStyle = getTopicStyle(topicKey);

              return (
                <TopicButton
                  key={index}
                  as={motion.button}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  isHighlighted={shouldHighlight}
                  style={{
                    backgroundColor: topicStyle.backgroundColor,
                    color: topicStyle.color,
                  }}
                  data-category={topicStyle.category}
                >
                  {t(topicKey)}
                  {shouldHighlight && <ShineEffect />}
                </TopicButton>
              );
            })}
          </FeaturedTopicsContainer>
        </>
      )}
    </CarouselContainer>
  );
};

export default IntroPage;
