import { baseBackendUrl } from "../utils/baseUrls";

export const startChatSession = async (userId: string, password: string) => {
  const response = await fetch(`${baseBackendUrl}/chat/start`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId, code: password }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      `HTTP error! status: ${response.status}, response: ${errorText}`
    );
  }

  return response;
};

interface ChatResponse {
  sessionId: string;
  content: string;
  followUps: string[];
  audio: string;
}

export const selectTopic = async (topicIndex: number) => {
  const response = await fetch(`${baseBackendUrl}/chat/select-topic`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "X-Source": window.location.origin,
    },
    body: JSON.stringify({
      sessionId: sessionStorage.getItem("sessionId"),
      topicId: topicIndex.toString(),
    }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      `HTTP error! status: ${response.status}, response: ${errorText}`
    );
  }

  return response;
};

  

export const sendChatMessage = async (sessionId: string, topic: string) => {
  const response = await fetch(`${baseBackendUrl}/chat/message`, {
    method: "POST",
    headers: {
      "x-api-key": process.env.REACT_APP_BACKEND_API_KEY || "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sessionId,
      topic,
    }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(
      `HTTP error! status: ${response.status}, response: ${errorText}`
    );
  }

  return response;
};

