import styled from "styled-components";

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const Row = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: #085cbe;
  padding: 8px;

  @media (max-width: 768px) {
    font-size: 24px;
    top: 15px;
    right: 15px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
    top: 10px;
    right: 10px;
  }
`;

export const Title = styled.h2`
  font-size: 32px;
  color: #085cbe;
  margin-bottom: 20px;
  font-weight: 700;

  @media (max-width: 1024px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 16px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
    margin-bottom: 14px;
  }
`;

export const Select = styled.select`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
  border: none;
  outline: none;
  width: 100%;
  max-width: 200px;
  color: #989898;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 12px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

export const LogoutButton = styled.button`
  background-color: #f37c7c;
  color: white;
  font-size: 24px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e56a6a;
  }

  @media (max-width: 1024px) {
    font-size: 22px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 8px 16px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    padding: 6px 14px;
  }
`;

interface RTLProps {
  $isRtl?: boolean;
}

export const DialogContent = styled.div<RTLProps>`
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #085cbe;
  max-height: 90vh;
  overflow-y: auto;
  direction: ${(props) => (props.$isRtl ? "rtl" : "ltr")};

  @media (max-width: 768px) {
    padding: 30px 20px;
    width: 90%;
  }

  @media (max-width: 480px) {
    padding: 20px 16px;
    width: 95%;
  }
`;

export const DialogTitle = styled.h1<RTLProps>`
  font-size: 36px;
  color: #085cbe;
  text-align: center;
  margin-block: 10px;
  font-weight: 700;
  direction: ${(props) => (props.$isRtl ? "rtl" : "ltr")};

  @media (max-width: 1024px) {
    font-size: 32px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const ButtonList = styled.div<RTLProps>`
  display: flex;
  flex-direction: column;
  width: 250px;
  border-right: ${(props) => (props.$isRtl ? "none" : "1px solid #ccc")};
  border-left: ${(props) => (props.$isRtl ? "1px solid #ccc" : "none")};
  padding-right: ${(props) => (props.$isRtl ? "0" : "60px")};
  padding-left: ${(props) => (props.$isRtl ? "60px" : "0")};

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #ccc;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`;

export const Button = styled.button<{
  $isselected: boolean;
  $isLogout?: boolean;
  $isRtl?: boolean;
}>`
  padding: 10px;
  margin: 5px 0;
  border: none;
  text-align: ${(props) => (props.$isRtl ? "right" : "left")};
  cursor: pointer;
  font-weight: 600;
  font-size: 24px;
  color: ${(props) =>
    props.$isLogout ? "#F37C7C" : props.$isselected ? "#085cbe" : "#989898"};
  background-color: transparent;
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => (props.$isLogout ? "#F37C7C" : "#085cbe")};
  }

  @media (max-width: 768px) {
    text-align: center;
    font-size: 20px;
  }
`;

export const ContentArea = styled.div<RTLProps>`
  flex-grow: 1;
  padding-left: ${(props) => (props.$isRtl ? "0" : "40px")};
  padding-right: ${(props) => (props.$isRtl ? "40px" : "0")};
  margin-top: 20px;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 10px;
  }
`;

export const ContentWrapper = styled.div<RTLProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-inline: 40px;
  direction: ${(props) => (props.$isRtl ? "rtl" : "ltr")};

  @media (max-width: 768px) {
    padding-inline: 20px;
  }

  @media (max-width: 480px) {
    padding-inline: 10px;
  }
`;

export const SubTitle = styled.h3<RTLProps>`
  font-size: 24px;
  color: #085cbe;
  margin-bottom: 15px;
  font-weight: 500;
  text-align: ${(props) => (props.$isRtl ? "right" : "left")};
  direction: ${(props) => (props.$isRtl ? "rtl" : "ltr")};

  @media (max-width: 1024px) {
    font-size: 22px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 12px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

export const Input = styled.input<RTLProps>`
  font-size: 18px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #085cbe;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  text-align: ${(props) => (props.$isRtl ? "right" : "left")};
  direction: ${(props) => (props.$isRtl ? "rtl" : "ltr")};

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 8px;
    margin-bottom: 12px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 6px;
    margin-bottom: 10px;
  }
`;