import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "./translations/en/translation.json";
import translationsDe from "./translations/de/translation.json";
import translationsHe from "./translations/he/translation.json";

i18n.use(initReactI18next).init({
  resources: {
    English: {
      translation: translationsEn,
    },
    German: {
      translation: translationsDe,
    },
    Hebrew: {
      translation: translationsHe,
    },
  },
  lng: "English",
  fallbackLng: "English",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
