import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { completeRegistration } from "../apis/loginApi";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import {
  MainContainer,
  LoginFormBox,
  LoginFormTitle,
  LoaderContainer,
} from "../styles/LoginFormStyles";

const ConfirmEmail: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const token = location.search.slice(1).split("=")[1];

        if (!token) {
          toast.error(t("invalidConfirmationLink"), {
            position: "bottom-center",
            style: { backgroundColor: "#EF4444", color: "white" },
          });
          setTimeout(() => navigate("/"), 3000);
          return;
        }

        const response = await completeRegistration(token);

        sessionStorage.setItem("token", response.token);
        if (response.chatbotId)
          sessionStorage.setItem("chatbotId", response.chatbotId);
        if (response.link) sessionStorage.setItem("link", response.link);
        if (response.code) sessionStorage.setItem("code", response.code);
        if (response.uid) sessionStorage.setItem("uid", response.uid);

        setTimeout(() => navigate("/login"), 2000);
      } catch (error) {
        console.error("Confirmation error:", error);
        toast.error(t("confirmationFailed"), {
          position: "bottom-center",
          style: { backgroundColor: "#EF4444", color: "white" },
        });
        setTimeout(() => navigate("/"), 3000);
      } finally {
        setLoading(false);
      }
    };

    confirmEmail();
  }, [location, navigate, t]);

  return (
    <MainContainer>
      <LoginFormBox>
        <LoginFormTitle>
          {loading ? t("confirmingEmail") : t("emailConfirmed")}
        </LoginFormTitle>
        {loading && (
          <LoaderContainer>
            <ClipLoader color="#4F46E5" size={40} />
          </LoaderContainer>
        )}
      </LoginFormBox>

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
        style={{ width: "100%", maxWidth: "400px" }}
      />
    </MainContainer>
  );
};

export default ConfirmEmail;
