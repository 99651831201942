import React from "react";
import { Select } from "../styles/SettigsDialogStyle";
import { Language } from "../apis/settingApi";

interface LangSwitchProps {
  languages: Language[];
  currentLanguage: string;
  onLanguageChange: (language: string) => void;
}

const LangSwitch: React.FC<LangSwitchProps> = ({
  languages,
  currentLanguage,
  onLanguageChange,
}) => {
  const currentEnglishName =
    languages.find((lang) => lang.alpha2 === currentLanguage)?.English ||
    currentLanguage;

  const handleLanguageChange = (newLanguage: string) => {
    onLanguageChange(newLanguage);
  };

  return (
    <Select
      value={currentEnglishName}
      onChange={(e) => handleLanguageChange(e.target.value)}
    >
      {languages.map((language) => (
        <option key={language.alpha2} value={language.English}>
          {language.English}
        </option>
      ))}
    </Select>
  );
};

export default LangSwitch;
