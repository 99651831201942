import React, { useEffect } from "react";
import { motion } from "framer-motion";

interface LoadingAnimationProps {
  avatarType?: string;
  getAvatarSrc: (type: string) => string;
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  avatarType = "1",
  getAvatarSrc,
}) => {
  useEffect(() => {
    const pauseAllAudio = () => {
      const audioElements = document.querySelectorAll("audio");
      audioElements.forEach((audio) => {
        if (!audio.paused) {
          audio.pause();
        }
      });
    };

    pauseAllAudio();
  }, []);

  const containerVariants = {
    initial: {
      backgroundColor: "rgba(255, 255, 255, 0.4)",
    },
    animate: {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      transition: {
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse" as const,
      },
    },
  };

  const avatarVariants = {
    initial: {
      x: "-100%",
      y: 0,
      rotate: 0,
      filter: "drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))",
    },
    animate: {
      x: "100%",
      y: [0, -30, 0, -30, 0],
      rotate: [0, -10, 0, 10, 0],
      filter: [
        "drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))",
        "drop-shadow(0 8px 16px rgba(0, 0, 0, 0.2))",
        "drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))",
      ],
      transition: {
        x: {
          duration: 3,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut",
        },
        y: {
          duration: 1.5,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut",
          times: [0, 0.25, 0.5, 0.75, 1],
        },
        rotate: {
          duration: 1.5,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut",
          times: [0, 0.25, 0.5, 0.75, 1],
        },
        filter: {
          duration: 1.5,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut",
        },
      },
    },
  };

  return (
    <motion.div
      className="fixed inset-0 flex items-center justify-center overflow-hidden"
      variants={containerVariants}
      initial="initial"
      animate="animate"
      style={{
        backdropFilter: "blur(8px)",
        WebkitBackdropFilter: "blur(8px)",
      }}
    >
      <motion.div
        className="w-48 h-48 md:w-64 md:h-64"
        variants={avatarVariants}
        initial="initial"
        animate="animate"
      >
        <img
          src={getAvatarSrc(avatarType)}
          alt="Loading animation"
          className="w-full h-full object-contain"
          style={{
            willChange: "transform",
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default LoadingAnimation;
