export const availableLanguages = [
  {
    alpha2: "en",
    English: "English",
  },
  {
    alpha2: "de",
    English: "German",
  },
  {
    alpha2: "he",
    English: "Hebrew",
  },
];


export enum AvatarType {
  CAT = "1",
  BIRD = "2",
  ROBOT = "3",
}

export enum ColorTheme {
  ORANGE_BLUE = "1",
  GREEN_DARK = "2",
  PURPLE_DARK = "3",
}

export enum AzureVoiceType {
  CORA = "en-US-CoraMultilingualNeural",
  BRANDON = "en-US-BrandonMultilingualNeural",
  EMMA = "en-US-EmmaMultilingualNeural",
}

export const themeColors = {
  "1": {
    background: "#EEF8FF",
    secondary: "#FFDDB5",
    text: "#085CBE",
    default: "#FFFFFF",
  },
  "2": {
    background: "#333B43",
    secondary: "#C0F2A9",
    text: "#B2CE5B",
    default: "#333B43",
  },
  "3": {
    background: "#4FB5C1",
    secondary: "#F9E7EF",
    text: "#C2185B",
    default: "#4FB5C1",
  },
} as const;