import styled from "styled-components";
import colors from "./colors";
import { RTLProps } from "../utils/interfaces";
import { Play, Pause } from "lucide-react";
import { themeColors } from "../utils/consts";

export const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f5f5f5;
  width: 500px;
`;

export const Step = styled.div<{ active: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.active ? colors.primary : colors.text)};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
`;

export const WelcomeTitle = styled.h1`
  font-size: 72px;
  color: ${colors.text};
  margin-bottom: 10px;
  font-weight: 700;
  width: 55%;
  text-align: center;
`;

export const InputField = styled.input`
  font-size: 32px;
  padding: 10px;
  width: 200px;
  text-align: center;
  margin-bottom: 20px;
  border: 2px solid ${colors.border};
  border-radius: 10px;
`;

export const VoiceSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    display: block;
    justify-content: space-between;
  }
`;

export const VoiceCircle = styled.div<{
  $isSelected: boolean;
  $bgColor: string;
}>`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: ${(props) => props.$bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  border: ${(props) => (props.$isSelected ? "4px solid #085CBE" : "none")};
  transform: ${(props) => (props.$isSelected ? "scale(1.1)" : "scale(1)")};
  box-shadow: ${(props) =>
    props.$isSelected ? "0 0 15px rgba(8, 92, 190, 0.3)" : "none"};

  @media (max-width: 480px) {
    width: 90px;
    height: 90px;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const PlayIcon = styled(Play)`
  color: #085cbe;
  width: 32px;
  height: 32px;
`;

export const PauseIcon = styled(Pause)`
  color: #085cbe;
  width: 32px;
  height: 32px;
`;

export const VoiceName = styled.span`
  color: #085cbe;
  font-size: 19px;
  font-weight: 500;
  margin-top: 12px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 12px;
  }
`;

export const VoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const AvatarSelectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    display: block;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 0;
  }
`;

export const VoiceOption = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #f0f0f0;
  cursor: pointer;
`;

export const ThemeSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
`;

export const WelcomeImage = styled.div`
  width: 200px;
  height: 200px;
  background-color: #f0f0f0;
  margin-bottom: 20px;
`;

export const DigitInputContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
`;

export const DigitInput = styled.input`
  width: 64px;
  height: 64px;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
  color: #085cbe;
  background-color: white;
  outline: none;

  &:focus {
    border-color: #085cbe;
  }
`;

export const AvatarOption = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 480px) {
    width: 120px;
    height: 120px;
  }
`;

export const ThemeOption = styled.div<{ colors: string[] }>`
  width: 324px;
  height: 81px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  position: relative;
  border: 1px solid #333b43;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }

  &::after {
    content: "";
    flex: 1;
    background-color: ${(props) => props.colors[0]};
  }

  & > span {
    flex: 1;
    background-color: ${(props) => props.colors[1]};
  }
  @media (max-width: 480px) {
    width: 200px;
    height: 50px;
  }
`;

export const Container = styled.div<RTLProps>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #ffffff;
  direction: ${(props) => (props.$isRTL ? "rtl" : "ltr")};
`;

export const PageContainer = styled.div<{ theme: string; dir?: "ltr" | "rtl" }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  position: relative;
  padding-top: 32px;
  background-color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.background ||
      themeColors["1"].background
    );
  }};
  direction: ${(props) => props.dir};
`;

export const Content = styled.div<RTLProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
  gap: 60px;

  @media (max-width: 480px) {
    gap: 0px;
  }
`;

export const Button = styled.button<RTLProps>`
  background-color: ${colors.lightBlue};
  color: ${colors.text};
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 600;
  border-radius: 10px;
  width: 324px;
`;

export const SkipButton = styled.button`
  background-color: ${colors.lightBlue};
  color: ${colors.text};
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 10px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const Title = styled.h1<RTLProps>`
  font-size: 42px;
  color: ${colors.text};
  margin-bottom: 10px;
  font-weight: 700;
  align-self: center;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 32px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

export const Subtitle = styled.p<RTLProps>`
  font-size: 24px;
  color: ${colors.text};
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 18px;
    margin-bottom: 0px;
  }
`;

export const Column = styled.div<RTLProps>`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
`;