import React from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  Title,
  Subtitle,
  ThemeOption,
  ThemeSelectionWrapper,
} from "../styles/botSetup.styles";

const ThemeSelection: React.FC<{
  setSelectedTheme: (theme: string) => void;
  selectedTheme: string;
}> = ({ setSelectedTheme, selectedTheme }) => {
  const handleSelect = (theme: string) => {
    sessionStorage.setItem("colorTheme", theme);
    setSelectedTheme(theme);
  };
  const { t } = useTranslation();

  return (
    <Column>
      <Subtitle>{t("select_theme")}</Subtitle>
      <ThemeSelectionWrapper>
        <ThemeOption
          onClick={() => handleSelect("1")}
          colors={["#FFA500", "#085CBE"]}
          style={{
            transform: selectedTheme === "1" ? "scale(1.05)" : "scale(1)",
            border:
              selectedTheme === "1" ? "3px solid #085CBE" : "1px solid #085CBE",
            boxShadow:
              selectedTheme === "1" ? "0 0 15px rgba(8, 92, 190, 0.3)" : "none",
          }}
        >
          <span></span>
        </ThemeOption>
        <ThemeOption
          onClick={() => handleSelect("2")}
          colors={["#333B43", "#95C11F"]}
          style={{
            transform: selectedTheme === "3" ? "scale(1.05)" : "scale(1)",
            border:
              selectedTheme === "3" ? "3px solid #085CBE" : "1px solid #333B43",
            boxShadow:
              selectedTheme === "3" ? "0 0 15px rgba(8, 92, 190, 0.3)" : "none",
          }}
        >
          <span></span>
        </ThemeOption>
        <ThemeOption
          onClick={() => handleSelect("3")}
          colors={["#C2185B", "#4FB5C1"]}
          style={{
            transform: selectedTheme === "2" ? "scale(1.05)" : "scale(1)",
            border:
              selectedTheme === "2" ? "3px solid #085CBE" : "1px solid #333B43",
            boxShadow:
              selectedTheme === "2" ? "0 0 15px rgba(8, 92, 190, 0.3)" : "none",
          }}
        >
          <span></span>
        </ThemeOption>
      </ThemeSelectionWrapper>
    </Column>
  );
};

export default ThemeSelection;
