import React, { FC, useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  Title,
  Subtitle,
  Content,
  Button,
  DigitInputContainer,
  DigitInput,
  SkipButton,
} from "../styles/botSetup.styles";
import { BirdImage, FoxImage } from "../styles/WelcomePageStyles";
import birdIcon from "../assets/birdBig.png";
import catIcon from "../assets/catBig.png";
import { useTranslation } from "react-i18next";
import {
  AllSetWrapper,
  BubbleText,
  LoadingOverlay,
  SpeechBubble,
  Spinner,
  StartButton,
  TextColumn,
  Image,
  NavigationButtons,
  CircleButton,
} from "../styles/chatPageStyle";
import { startChatSession } from "../apis/chatApi";
import { updateTutor } from "../apis/settingApi";
import { availableLanguages, AvatarType, ColorTheme } from "../utils/consts";
import { LanguageContext } from "../hooks/languageContext";
import {
  BotSetupProps,
  ChatSessionResponse,
  CodeInputProps,
  LanguageMap,
  UserConfig,
} from "../utils/interfaces";
// import VoiceSelection from "../components/VoiceSelection";
import ThemeSelection from "../components/ThemeSelection";
import AvatarSelection from "../components/AvatarSelection";
import { ArrowLeft } from "lucide-react";

const BotSetup: FC<BotSetupProps> = ({
  onComplete,
  onSessionData,
  initialStep,
}) => {
  const [step, setStep] = useState(initialStep || 0);
  const [code, setCode] = useState<string>("");
  const [selectedAvatar, setSelectedAvatar] = useState<string>("");
  const [selectedVoice, setSelectedVoice] = useState<string>("");
  const [selectedTheme, setSelectedTheme] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const isRTL = language === "Hebrew" || language === "he";
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId") ?? "";

  useEffect(() => {
    const urlLang = urlParams.get("lang");
    if (urlLang) {
      const languageMap: LanguageMap = {
        he: { alpha2: "he", English: "Hebrew" },
        en: { alpha2: "en", English: "English" },
        de: { alpha2: "de", English: "German" },
      };

      const languageMapping = languageMap[urlLang];
      if (
        languageMapping &&
        availableLanguages
          .map((lang) => lang.English)
          .includes(languageMapping.English)
      ) {
        changeLanguage(languageMapping.English);
      }
    }
  }, [changeLanguage]);

  const validateCode = async () => {
    if (code.length !== 4) {
      setError("Please enter all 4 digits");
      return false;
    }

    setIsLoading(true);
    try {
      const response = await startChatSession(userId, code);
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        setError(
          errorData?.message || "Something went wrong. Please try again later."
        );
        return false;
      }

      const data: ChatSessionResponse = await response.json();
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value === "object") {
          sessionStorage.setItem(key, JSON.stringify(value));
        } else {
          sessionStorage.setItem(key, String(value));
        }
      });

      if (!data.userDoc.setupComplete) {
        setStep(step + 1);
      } else {
        onSessionData(data);
      }

      return true;
    } catch (error) {
      console.error("Error validating code:", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const updateAvatarType = async (avatar: string) => {
    let avatarTypeValue: AvatarType;
    switch (avatar) {
      case "cat":
        avatarTypeValue = AvatarType.CAT;
        break;
      case "bird":
        avatarTypeValue = AvatarType.BIRD;
        break;
      case "robot":
        avatarTypeValue = AvatarType.ROBOT;
        break;
      default:
        return;
    }

    try {
      const chatbotId = sessionStorage.getItem("chatbotId");
      if (!chatbotId) {
        throw new Error("Chatbot ID not found");
      }

      await updateTutor(
        chatbotId,
        sessionStorage.getItem("token") || "",
        undefined,
        undefined,
        undefined,
        undefined,
        avatarTypeValue
      );
      sessionStorage.setItem("avatarType", avatarTypeValue);
    } catch (error) {
      console.error("Error updating avatar type:", error);
    }
  };

  const updateVoiceType = async (voice: string) => {
    let voiceName: string;
    switch (voice) {
      case "voice1":
        voiceName = "CORA";
        break;
      case "voice2":
        voiceName = "BRANDON";
        break;
      case "voice3":
        voiceName = "EMMA";
        break;
      default:
        return;
    }

    try {
      const chatbotId = sessionStorage.getItem("chatbotId");
      if (!chatbotId) {
        throw new Error("Chatbot ID not found");
      }

      await updateTutor(
        chatbotId,
        sessionStorage.getItem("token") || "",
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        voiceName
      );
    } catch (error) {
      console.error("Error updating voice type:", error);
    }
  };

  const updateThemeType = async (theme: string) => {
    let themeValue: ColorTheme;
    switch (theme) {
      case "theme1":
        themeValue = ColorTheme.ORANGE_BLUE;
        break;
      case "theme2":
        themeValue = ColorTheme.PURPLE_DARK;
        break;
      case "theme3":
        themeValue = ColorTheme.GREEN_DARK;
        break;
      default:
        return;
    }

    try {
      const chatbotId = sessionStorage.getItem("chatbotId");
      if (!chatbotId) {
        throw new Error("Chatbot ID not found");
      }

      await updateTutor(
        chatbotId,
        sessionStorage.getItem("token") || "",
        undefined,
        undefined,
        undefined,
        themeValue
      );
      sessionStorage.setItem("colorTheme", themeValue);
    } catch (error) {
      console.error("Error updating color theme:", error);
    }
  };

  const handleAvatarSelection = (avatar: string) => {
    sessionStorage.setItem("avatarType", avatar);
    setSelectedAvatar(avatar);
    nextStep();
  };

  const handleThemeSelection = (theme: string) => {
    sessionStorage.setItem("colorTheme", theme);
    setSelectedTheme;
    nextStep();
  };

  const nextStep = async () => {
    if (step === 1) {
      const isValid = await validateCode();
      if (!isValid) return;
    } else if (step === 2 && selectedAvatar) {
      await updateAvatarType(selectedAvatar);
    } else if (step === 3 && selectedVoice) {
      await updateVoiceType(selectedVoice);
    } else if (step === 4 && selectedTheme) {
      await updateThemeType(selectedTheme);
    }
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const steps = [
    { title: "Welcome", component: <Welcome /> },
    {
      title: "Code",
      component: (
        <CodeInput
          code={code}
          setCode={setCode}
          error={error}
          setError={setError}
        />
      ),
    },
    {
      title: "Avatar",
      component: (
        <AvatarSelection
          setSelectedAvatar={handleAvatarSelection}
          selectedAvatar={selectedAvatar}
        />
      ),
    },
    // {
    //   title: "Voice",
    //   component: (
    //     <VoiceSelection
    //       setSelectedVoice={setSelectedVoice}
    //       selectedVoice={selectedVoice}
    //     />
    //   ),
    // },
    {
      title: "Theme",
      component: (
        <ThemeSelection
          setSelectedTheme={handleThemeSelection}
          selectedTheme={selectedTheme}
        />
      ),
    },
    {
      title: "All Set",
      component: (
        <AllSet
          onCompleteClicked={onComplete}
          selectedAvatar={selectedAvatar}
          selectedVoice={selectedVoice}
          selectedTheme={selectedTheme}
          prevStep={prevStep}
        />
      ),
    },
  ];

  const skipToChat = async () => {
    try {
      await onComplete({});
    } catch (error) {
      console.error("Error skipping to chat:", error);
    }
  };

  const renderNavigationButtons = () => {
    const isSelectionStep = step >= 2 && step <= 6;

    if (!isSelectionStep) {
      if (step === 0) {
        return (
          <Button onClick={nextStep} $isRTL={isRTL}>
            {t("login")}
          </Button>
        );
      } else if (step === 1) {
        return (
          <Button
            onClick={nextStep}
            disabled={code.length !== 4}
            $isRTL={isRTL}
          >
            {t("next")}
          </Button>
        );
      }
      return null;
    }

    return (
      <NavigationButtons>
        {step != 2 && (
          <CircleButton onClick={prevStep}>
            <ArrowLeft size={16} />
          </CircleButton>
        )}
        {/* {step === 3 && (
          <CircleButton onClick={nextStep}>
            <ArrowRight size={16} />
          </CircleButton>
        )} */}
      </NavigationButtons>
    );
  };

  return (
    <Container $isRTL={isRTL}>
      {isLoading && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
      <Content $isRTL={isRTL}>
        {step === 0 && (
          <Title $isRTL={isRTL}>
            {t("welcome")}{" "}
            <span style={{ color: "#FFA500" }}>
              {t("tamerino")}
              <span style={{ color: "#085CBE" }}>!</span>{" "}
            </span>
          </Title>
        )}
        {steps[step].component}
        {step < 2 && (
          <Button
            onClick={nextStep}
            disabled={step === 1 && code.length !== 4}
            $isRTL={isRTL}
          >
            {step === 0 ? t("login") : t("next")}
          </Button>
        )}
        {step >= 2 && step <= 5 && renderNavigationButtons()}
        {step >= 2 && step <= 4 && (
          <SkipButton onClick={skipToChat}>{t("skip")}</SkipButton>
        )}{" "}
        {error && (
          <div
            style={{
              color: "red",
              marginTop: "10px",
              textAlign: isRTL ? "right" : "left",
              direction: isRTL ? "rtl" : "ltr",
              width: "100%",
            }}
          >
            {t(error)}
          </div>
        )}
      </Content>
      {step === 0 && (
        <>
          <BirdImage src={birdIcon} alt={t("bird_alt")} />
          <FoxImage src={catIcon} alt={t("fox_alt")} />
        </>
      )}
    </Container>
  );
};

const Welcome: React.FC = () => <></>;

const CodeInput: React.FC<CodeInputProps> = ({ code, setCode, setError }) => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const isRTL = language === "Hebrew" || language === "he";
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const handleChange = (index: number, value: string) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newCode = code.split("");
      newCode[index] = value;
      const updatedCode = newCode.join("");
      setCode(updatedCode);
      setError("");
      if (value && index < 3) {
        inputRefs[index + 1].current?.focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs[index - 1].current?.focus();
    }
  };

  useEffect(() => {
    inputRefs[0].current?.focus();
  }, []);

  return (
    <>
      <Title $isRTL={isRTL}>{t("enter_code")}</Title>
      <Subtitle $isRTL={isRTL}>{t("code_instruction")}</Subtitle>
      <DigitInputContainer style={{ direction: "ltr" }}>
        {" "}
        {[0, 1, 2, 3].map((index) => (
          <DigitInput
            key={index}
            ref={inputRefs[index]}
            type="text"
            maxLength={1}
            value={code[index] || ""}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            style={{
              direction: "ltr",
              textAlign: "center",
            }}
          />
        ))}
      </DigitInputContainer>
    </>
  );
};

interface AllSetProps {
  onCompleteClicked: (config: Partial<UserConfig>) => Promise<void>;
  selectedAvatar: string;
  selectedVoice: string;
  selectedTheme: string;
  prevStep: () => void;
}

const AllSet: React.FC<AllSetProps> = ({
  onCompleteClicked,
  selectedAvatar,
  selectedVoice,
  selectedTheme,
  prevStep,
}) => {
  const { t } = useTranslation();
  const currentTheme = sessionStorage.getItem("colorTheme") || "1";

  const handleComplete = async () => {
    try {
      await onCompleteClicked({
        selectedAvatar,
        selectedVoice,
        selectedTheme,
      });
    } catch (error) {
      console.error("Error completing setup:", error);
    }
  };

  const getAvatarSrc = (avatarType: string) => {
    switch (avatarType) {
      case "1":
        return require("../assets/allSetCat.png");
      case "2":
        return require("../assets/allsetBird.png");
      case "3":
        return require("../assets/allsetRobot.png");
      default:
        return require("../assets/allSet.png");
    }
  };

  const selectedImage = getAvatarSrc(selectedAvatar);

  return (
    <AllSetWrapper theme={currentTheme}>
      <SpeechBubble>
        <TextColumn>
          <BubbleText>{t("all_set")}</BubbleText>
          <BubbleText>{t("start_discovering")}</BubbleText>
        </TextColumn>
      </SpeechBubble>
      <Image src={selectedImage} alt="avatar" />
      <StartButton
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleComplete}
      >
        {t("click_to_start")}
      </StartButton>
      <NavigationButtons>
        <CircleButton onClick={prevStep}>
          <ArrowLeft size={16} />
        </CircleButton>
      </NavigationButtons>
    </AllSetWrapper>
  );
};

export default BotSetup;
