import { useState, useRef, FC, useEffect } from "react";
import { styled } from "styled-components";
import { FaPlay, FaPause } from "react-icons/fa";
import { themeColors } from "../utils/consts";

const PlayerContainer = styled.div<{ theme: string }>`
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.secondary ||
      themeColors["1"].secondary
    );
  }};
  padding: 8px 16px;
  border-radius: 24px;
  width: fit-content;
`;

const PlayButton = styled.button<{ theme: string }>`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.text ||
      themeColors["1"].text
    );
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  svg {
    width: 12px;
    height: 12px;
  }
`;

const ProgressBar = styled.input<{ theme: string }>`
  -webkit-appearance: none;
  max-width: 200px;
  height: 4px;
  background-color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.background ||
      themeColors["1"].background
    );
  }};
  border-radius: 2px;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${(props) => {
      const theme = props.theme || "1";
      return (
        themeColors[theme as keyof typeof themeColors]?.text ||
        themeColors["1"].text
      );
    }};
    cursor: pointer;
  }
`;

const SpeedButton = styled.button<{ theme: string }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.text ||
      themeColors["1"].text
    );
  }};
  font-size: 14px;
  padding: 0;
`;

const CustomAudioPlayer: FC<{
  src: string;
  index: number;
  onPlay: (index: number) => void;
  onPause: () => void;
  onEnded: () => void;
  theme: string;
}> = ({ src, index, onPlay, onPause, onEnded }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const audioRef = useRef<HTMLAudioElement>(null);
  const allowedSpeeds = [0.75, 1, 1.25];
  const currentTheme = sessionStorage.getItem("colorTheme") || "1";

  useEffect(() => {
    if (!audioRef.current) return;

    const currentAudio = audioRef.current;
    (window as any).audioRefArray = (window as any).audioRefArray || [];
    (window as any).audioRefArray[index] = currentAudio;

    return () => {
      (window as any).audioRefArray[index] = null;
    };
  }, [index]);

  useEffect(() => {
    if (!audioRef.current) return;

    const currentAudio = audioRef.current;

    const handlePause = () => {
      setIsPlaying(false);
      onPause();
    };

    currentAudio.addEventListener("pause", handlePause);

    return () => {
      currentAudio.removeEventListener("pause", handlePause);
    };
  }, [onPause]);

  const pauseAllOtherAudio = () => {
    const allAudioElements = document.querySelectorAll("audio");
    allAudioElements.forEach((audio) => {
      if (audio !== audioRef.current && !audio.paused) {
        audio.pause();
      }
    });
  };

  useEffect(() => {
    handleAudioPause();
  }, [index]);

  const handlePlayPause = async () => {
    if (!audioRef.current) return;

    try {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
        onPause();
      } else {
        pauseAllOtherAudio();
        await audioRef.current.play();
        setIsPlaying(true);
        onPlay(index);
      }
    } catch (error) {
      console.error("Error in play/pause:", error);
      setIsPlaying(false);
      onPause();
    }
  };

  const handleSpeedClick = () => {
    if (audioRef.current) {
      const currentIndex = allowedSpeeds.indexOf(playbackRate);
      const nextIndex = (currentIndex + 1) % allowedSpeeds.length;
      const newSpeed = allowedSpeeds[nextIndex];
      audioRef.current.playbackRate = newSpeed;
      setPlaybackRate(newSpeed);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleAudioPlay = () => {
    pauseAllOtherAudio();
    setIsPlaying(true);
    onPlay(index);
  };

  const handleAudioPause = () => {
    setIsPlaying(false);
    onPause();
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
    setCurrentTime(0);
    onEnded();
  };

  return (
    <PlayerContainer theme={currentTheme}>
      <PlayButton onClick={handlePlayPause}>
        {isPlaying ? <FaPause /> : <FaPlay />}
      </PlayButton>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onPlay={handleAudioPlay}
        onPause={handleAudioPause}
        onEnded={handleAudioEnded}
        autoPlay
      />

      <ProgressBar
        type="range"
        min={0}
        max={duration}
        value={currentTime}
        onChange={(e) => {
          if (audioRef.current) {
            audioRef.current.currentTime = Number(e.target.value);
            setCurrentTime(Number(e.target.value));
          }
        }}
      />

      <SpeedButton onClick={handleSpeedClick}>
        {playbackRate === 1 ? "1x" : `${playbackRate}x`}
      </SpeedButton>
    </PlayerContainer>
  );
};

export default CustomAudioPlayer;
